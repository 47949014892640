export const StepStatus = {
  ERROR: -1,
  WAIT: 0,
  PROCESS: 1,
  FINISH: 2,
};

export const getStatusTextByNum = (text) => Object.keys(StepStatus).find((key) => {
  if (StepStatus[key] === text) {
    return key;
  }
  return null;
});

export const StepStatusColor = {
  ERROR: 'error',
  WAIT: 'default',
  PROCESS: 'blue',
  FINISH: 'green',
};

export const getStatusColorByNum = (num) => {
  const status = getStatusTextByNum(num);
  return StepStatusColor[status];
};
