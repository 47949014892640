const PlanStatus = {
  Declined: -2,
  Deactivated: -1,
  ToDo: 1,
  InProgress: 2,
  AwaitingVerification: 3,
  VerificationFailed: 4,
  Rejected: 4,
  Done: 5,
  Approved: 6,
};

export { PlanStatus };
