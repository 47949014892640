import { PermissionMiddleware } from '@/router/Middleware/PermissionMiddleware';

const serviceRoutes = [
  {
    path: '/service',
    name: 'Service',
    component: () => import('../Pages/Service/Upload/index.vue'),
    meta: {
      name: 'Загрузка планировки',
      roles: ['admin', 'user', 'ninja'],
    },
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import('../Pages/Service/Plans/index.vue'),
    meta: {
      name: 'Планировки',
      roles: ['admin', 'user', 'ninja'],
    },
  },
  {
    path: '/plans/:id',
    name: 'plans-show',
    component: () => import('../Pages/Service/Plans/id.vue'),
    meta: {
      name: 'Планировка',
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../Pages/Service/Users/index.vue'),
    meta: {
      name: 'Пользователи',
      roles: ['admin', 'manager'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/furniture',
    name: 'furniture',
    component: () => import('../Pages/Service/Furniture/index.vue'),
    meta: {
      name: 'Мебель',
      roles: ['admin', 'manager', 'user'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/furniture/:id',
    name: 'furniture-show',
    component: () => import('../Pages/Service/Furniture/id.vue'),
    meta: {
      name: 'Мебель',
      roles: ['admin', 'manager', 'user'],
    },
  },
  {
    path: '/svg-editor/icons',
    name: 'svg-editor-icons',
    component: () => import('../Pages/Service/SvgEditorFurniture/index.vue'),
    meta: {
      name: 'Конструктор SVG - Фурнитура',
      roles: [],
    },
  },
  {
    path: '/design-files',
    name: 'design-files',
    component: () => import('../Pages/Service/DesignFile/index.vue'),
    meta: {
      name: 'Дизайн-документы',
      roles: ['admin'],
    },
  },
  {
    path: '/substances',
    name: 'Substances',
    component: () => import('../Pages/Service/Substances/index.vue'),
    meta: {
      name: 'Сущности',
      roles: ['admin', 'manager'],
    },
  },
  {
    path: '/neural_dataset_selection',
    name: 'DatasetSelectionPlans',
    component: () => import('../Pages/Service/DatasetSelectionPlans/index.vue'),
    meta: {
      name: 'Сбор датасета для обучения нейронки',
      roles: ['manager', 'ninja', 'admin'],
    },
  },
  {
    path: '/neural_dataset_selection/datasets/:dataset/get_to_work',
    name: 'GetToWork',
    component: () => import('../Components/Service/DatasetSelectionPlans/GetToWork.vue'),
    meta: {
      name: 'Сбор датасета для обучения нейронки',
      roles: ['manager', 'ninja', 'admin'],
    },
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: () => import('../Pages/ErrorPage.vue'),
    meta: {
      name: 'Ошибка сервера',
      roles: [],
    },
  },
];

export { serviceRoutes };
