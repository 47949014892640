import { api } from '@/api';
import { Role } from '@/enums/roles';
import { orderBy, sumBy } from 'lodash';
import { PlanType } from '@/enums/planType';

const moveNinjaDataToPercents = (data) => {
  const completedWithoutRejectedByManagerPercent = data.approvedByManager !== 0
    ? (data.completedWithoutRejectedByManager / data.approvedByManager) * 100
    : 0;

  const completedWithoutRejectedBySupervisorPercent = data.approvedBySupervisor !== 0
    ? (data.completedWithoutRejectedBySupervisor / data.approvedBySupervisor) * 100
    : 0;

  return {
    ...data,
    completedWithoutRejectedByManagerPercent: `${completedWithoutRejectedByManagerPercent.toFixed(2)}%`,
    completedWithoutRejectedBySupervisorPercent: `${completedWithoutRejectedBySupervisorPercent.toFixed(2)}%`,
  };
};

const getNinjaStatistict = (planType) => {
  switch (planType) {
    case PlanType.Type3d:
      return api.getNinjaStatistic3dById;
    case PlanType.Type2d:
    default:
      return api.getNinjaStatisticById;
  }
};

export default {
  namespaced: true,
  state: {
    ninjaData: [],
  },
  mutations: {
    addData(state, value) {
      state.ninjaData = orderBy([
        ...state.ninjaData,
        value,
      ], ['name']);
    },
    resetData(state) {
      state.ninjaData = [];
    },
  },
  getters: {
    approvedByManagerSum: ({ ninjaData }) => sumBy(ninjaData, 'approvedByManager'),
    approvedBySupervisorSum: ({ ninjaData }) => sumBy(ninjaData, 'approvedBySupervisor'),

    completedWithoutRejectedByManagerSum: ({ ninjaData }) => sumBy(ninjaData, 'completedWithoutRejectedByManager'),
    completedWithoutRejectedBySupervisorSum: ({ ninjaData }) => sumBy(
      ninjaData,
      'completedWithoutRejectedBySupervisor',
    ),

    fixedSum: ({ ninjaData }) => sumBy(ninjaData, 'fixedCount'),
    fixedAndApprovedSum: ({ ninjaData }) => sumBy(ninjaData, 'fixedAndApprovedCount'),

    unfinishedSum: ({ ninjaData }) => sumBy(ninjaData, 'unfinishedCount'),
    completedDuplicatesSum: ({ ninjaData }) => sumBy(ninjaData, 'completedDuplicatesCount'),

    completedWithoutRejectedByManagerPercent: ({ ninjaData }) => {
      const allSum = sumBy(ninjaData, 'approvedByManager');
      const withoutFailsSum = sumBy(ninjaData, 'completedWithoutRejectedByManager');

      const percent = allSum !== 0 ? (withoutFailsSum / allSum) * 100 : 0;

      return `${percent.toFixed(2)}%`;
    },
    completedWithoutRejectedBySupervisorPercent: ({ ninjaData }) => {
      const allSum = sumBy(ninjaData, 'approvedBySupervisor');
      const withoutFailsSum = sumBy(ninjaData, 'completedWithoutRejectedBySupervisor');

      const percent = allSum !== 0 ? (withoutFailsSum / allSum) * 100 : 0;

      return `${percent.toFixed(2)}%`;
    },
  },
  actions: {
    async getNinjaStatistic(
      {
        rootGetters,
        rootState,
        commit,
        dispatch,
      },
      {
        fromDate,
        toDate,
        type,
      },
    ) {
      commit('resetData');

      const currentUserHasRoles = rootGetters['auth/currentUserHasRoles'];
      const canUserSelectEveryOption = currentUserHasRoles(Role.Manager, Role.Admin);
      if (canUserSelectEveryOption) {
        await dispatch('crm/getSimpleUsersList', null, { root: true });
      }
      const users = canUserSelectEveryOption ? rootGetters['crm/simpleNinjasList'] : [rootState.auth.currentUser];

      const params = {
        fromDate: fromDate.format('DD-MM-YYYY'),
        toDate: toDate.format('DD-MM-YYYY'),
      };

      users.forEach((user) => {
        const method = getNinjaStatistict(type.value);
        method(user.id, params).then(({ data }) => {
          const item = {
            id: user.id,
            key: user.id,
            name: user.name,
            ...data,
          };

          commit('addData', moveNinjaDataToPercents(item));
        });
      });
    },
  },
};
