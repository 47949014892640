<template>
  <div />
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'YandexMetrika',
  setup() {
    const store = useStore();

    const initializeYandexMetrika = () => {
      const authUser = computed(() => store.state.auth.currentUser);

      if (authUser.value && typeof window.ym === 'function') {
        window.ym(97097353, 'userParams', {
          UserID: authUser.value.id,
          beta_tester: authUser.value.beta_tester,
          roles: authUser.value.roles.map((role) => role.name),
          email: authUser.value.email,
          name: authUser.value.name,
          ninja_expert_level_2d: authUser.value.ninja_expert_level_2d,
          ninja_expert_level_3d: authUser.value.ninja_expert_level_3d,
        });
      }
    };

    onMounted(() => {
      if (typeof window.ym === 'function') {
        initializeYandexMetrika();
      } else {
        setTimeout(initializeYandexMetrika, 1000);
      }
    });
  },
};
</script>
