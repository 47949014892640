import {
  widgetRoute, panoramaRoute, widgetEnRoute, panoramaEnRoute,
} from './widgetRoutes';

export const publicPlanRoute = {
  path: '/public_plans/:id',
  pathname: '/public_plans',
  name: 'Public planId',
  component: () => import('../Pages/PublicPlan/index.vue'),
  meta: {
    name: 'Публичная планировка',
    roles: [],
  },
};

export const publicRoutes = [widgetRoute, panoramaRoute, publicPlanRoute, widgetEnRoute, panoramaEnRoute];
