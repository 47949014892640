import DatePicker from 'ant-design-vue/es/date-picker/moment';
import TimePicker from 'ant-design-vue/es/time-picker/moment';
import Calendar from 'ant-design-vue/es/calendar/moment';
import { createApp } from 'vue';
import Antd, { message } from 'ant-design-vue';
import { httpClient } from '@/httpClient';
import VueViewer from 'v-viewer';
import InfiniteScroll from 'vue-infinite-scroll';
import * as Sentry from '@sentry/vue';
import { captureConsoleIntegration } from '@sentry/integrations';
import Config from '@/config'; // eslint-disable-line import/extensions
import LoadScript from 'vue-plugin-load-script';
import App from './App.vue';
import { router } from './router';
import store from './store';
import 'ant-design-vue/dist/reset.css';
import 'viewerjs/dist/viewer.css';

const app = createApp(App).use(Antd).use(VueViewer).use(InfiniteScroll);
app.use(DatePicker).use(TimePicker).use(Calendar);

app.config.productionTip = false;

const renderApp = () => app.use(store).use(router).use(LoadScript).mount('#app');

const token = localStorage.getItem('auth-token');

if (token) {
  store.commit('auth/login');
  httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  store.dispatch('auth/getCurrentUser')
    .catch(() => {
      message.error('Недействительный токен');
      store.commit('auth/logout');
      router.push('/auth');
    })
    .finally(renderApp);
} else {
  renderApp();
}

Sentry.init({
  app,
  dsn: Config.sentry.dsn,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    captureConsoleIntegration(['error']),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.005,
  environment: Config.sentry.environment,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});
