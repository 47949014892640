import config from '@/config'; // eslint-disable-line import/extensions
import logo from '@/assets/img/logo_opacity.svg';

const lazyWidgetPageComponent = () => import('@/Pages/Widget/index.vue');

export const widgetRoute = {
  path: '/widget',
  pathname: '/widget',
  name: 'Widget page',
  component: lazyWidgetPageComponent,
  props: {
    lang: 'ru',
    logo,
    logoUrl: config.widget.logoUrl,
  },
  meta: {
    name: 'Виджет',
    roles: [],
  },
};

export const widgetEnRoute = {
  path: '/widget/en',
  pathname: '/widget/en',
  name: 'Widget page en',
  component: lazyWidgetPageComponent,
  props: {
    lang: 'en',
    logo,
    logoUrl: config.widget.logoUrlEn,
  },
  meta: {
    name: 'Widget',
    roles: [],
  },
};

export const panoramaRoute = {
  path: '/widget/panorama',
  pathname: '/widget/panorama',
  name: 'Widget panorama page',
  component: lazyWidgetPageComponent,
  props: { tabs: ['panorama'] },
  meta: {
    name: 'Панорамный тур',
    roles: [],
  },
};

export const panoramaEnRoute = {
  path: '/widget/en/panorama',
  pathname: '/widget/en/panorama',
  name: 'Widget panorama page en',
  component: lazyWidgetPageComponent,
  props: {
    lang: 'en',
    logo,
    logoUrl: config.widget.logoUrlEn,
    tabs: ['panorama'],
  },
  meta: {
    name: 'Panoramic tour',
    roles: [],
  },
};
