import { api } from '@/api';

const parseItems = (items) => items.map((item, idx) => ({
  key: idx + item.id,
  id: item.id,
  imageUrl: item.paths.plan,
  status: item.status,
}));

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,
  },
  mutations: {
    setItems(state, value) {
      state.items = parseItems(value);
    },
    setLoadingState(state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    async get({ commit }, { page, filter }) {
      commit('setLoadingState', true);
      const response = await api.getServicePlanList({ page, filter })
        .finally(() => commit('setLoadingState', false));

      if (response.status < 200 || response.status > 299) {
        throw Error('Проблема респонса');
      }

      const { data } = response;

      commit('setItems', data.items);

      return data;
    },
  },
  getters: {},
};
