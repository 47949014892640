/**
 * From Antd docs
 *
 * @type {{xl: number, md: number, sm: number, xs: number, lg: number, xxl: number}}
 */
export const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
