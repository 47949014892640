import { PlanType } from '@/enums/planType';
import { PermissionMiddleware } from '@/router/Middleware/PermissionMiddleware';

const crmRoutes = [
  {
    path: '/crm/plans',
    name: 'crm-plans',
    component: () => import('../Pages/CRM/Plans/index.vue'),
    meta: {
      name: 'CRM - Планировки',
      roles: ['admin', 'manager', 'cutter', 'ninja'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/plans-database',
    name: 'crm-plans-database',
    component: () => import('../Pages/CRM/PlansDatabase/index.vue'),
    meta: {
      name: 'CRM - База планировок',
      roles: ['admin', 'manager', 'cutter', 'ninja'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/plans/:id',
    name: 'crm-plans-show',
    component: () => import('../Pages/CRM/Plans/id.vue'),
    meta: {
      name: 'CRM - Планировка',
      roles: ['admin', 'manager', 'cutter', 'ninja', 'supervisor'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/plans/3d/:id',
    name: 'crm-plans3d-show',
    component: () => import('../Pages/CRM/Plans/id-3d.vue'),
    meta: {
      name: 'Планировка 3D',
      roles: ['admin', 'manager', 'supervisor', 'ninja'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/upload',
    name: 'crm-upload',
    component: () => import('../Pages/CRM/Upload/index.vue'),
    meta: {
      name: 'CRM - Загрузка планировки',
      roles: ['admin', 'manager', 'cutter'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/my-plans',
    name: 'crm-my-plans',
    component: () => import('../Pages/CRM/MyPlans/index.vue'),
    meta: {
      name: 'CRM - Мои планировки',
      roles: ['admin', 'ninja'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/apartments',
    name: 'crm-apartments',
    component: () => import('../Pages/CRM/Apartments/index.vue'),
    meta: {
      name: 'CRM - Апартаменты',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/apartments/:id',
    name: 'crm-apartments-id',
    component: () => import('../Pages/CRM/Apartments/id.vue'),
    meta: {
      name: 'CRM - Апартаменты',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
    beforeEnter: PermissionMiddleware,
  },
  {
    path: '/crm/apartment-programs',
    name: 'crm-apartment-program',
    component: () => import('../Pages/CRM/ApartmentProgram/index.vue'),
    meta: {
      name: 'CRM - Квартирограммы',
      roles: ['admin', 'manager', 'cutter'],
    },
  },
  {
    path: '/crm/apartment-programs/:id',
    name: 'crm-apartment-program-id',
    component: () => import('../Pages/CRM/ApartmentProgram/id.vue'),
    meta: {
      name: 'CRM - Квартирограмма по тэгу',
      roles: ['admin', 'manager', 'cutter'],
    },
  },
  {
    path: '/crm/apartment-programs/plans/:id',
    name: 'crm-apartment-program-plans',
    component: () => import('../Pages/CRM/ApartmentProgram/plans.vue'),
    meta: {
      name: 'CRM - Планировки квартирограммы',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
  },
  {
    path: '/crm/apartment-programs/plans/3d/:id',
    name: 'crm-apartment-program-plans-3d',
    component: () => import('../Pages/CRM/ApartmentProgram/plans-3d.vue'),
    meta: {
      name: 'CRM - Планировки квартирограммы',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
  },
  // {
  //   path: '/crm/statistic/ninja',
  //   name: 'crm-statistic-ninja',
  //   component: () => import('../Pages/CRM/Statistic/ninja.vue'),
  //   meta: {
  //     name: 'CRM - Статистика по ниндзя',
  //     roles: ['admin', 'manager', 'ninja'],
  //   },
  // },
  {
    path: '/crm/apartments/:id/plans/:planId',
    name: 'crm-apartments-plans-show',
    component: () => import('../Pages/CRM/Apartments/plans.vue'),
    props: {
      type: PlanType.Type2d,
    },
    meta: {
      name: 'CRM - Планировки апартамента',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
  },
  {
    path: '/crm/apartments/:id/plans/3d/:planId',
    name: 'crm-apartments-plans-3d-show',
    component: () => import('../Pages/CRM/Apartments/plans.vue'),
    props: {
      type: PlanType.Type3d,
    },
    meta: {
      name: 'CRM - Планировки апартамента',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
  },
  {
    path: '/crm/statistic/particularNinja',
    name: 'crm-statistic-particular-ninja',
    component: () => import('../Pages/CRM/ParticularNinjaStatistic/index.vue'),
    meta: {
      name: 'CRM - Статистика по отдельным ниндзя',
      roles: ['admin', 'manager', 'ninja'],
    },
  },
  {
    path: '/crm/plans/cutting',
    name: 'crm-cutting-plans',
    component: () => import('../Pages/CRM/CuttingPlans/index.vue'),
    meta: {
      name: 'CRM - Нарезка планировок',
      roles: ['admin', 'cutter'],
    },
  },
  {
    path: '/crm/plans/cutting/:id',
    name: 'crm-cutting-plans-show',
    component: () => import('../Pages/CRM/CuttingPlans/id.vue'),
    meta: {
      name: 'CRM - Нарезка планировок',
      roles: ['admin', 'cutter'],
    },
  },
  {
    path: '/crm/customers',
    name: 'crm-customers',
    component: () => import('../Pages/CRM/Customers/index.vue'),
    meta: {
      name: 'CRM - Заказчики',
      roles: ['admin', 'manager', 'cutter', 'supervisor'],
    },
    beforeEnter: PermissionMiddleware,
  },

];

export { crmRoutes };
