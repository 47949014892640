import { api } from '@/api';
import { message } from 'ant-design-vue';

export default {
  namespaced: true,
  state: {
    items: [],
    isLoading: false,

    uploadModal: {
      isVisible: false,
    },
  },
  mutations: {
    setSvg(state, items) {
      state.items = items;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setUploadModalVisibility(state, visibility) {
      state.uploadModal.isVisible = visibility;
    },
  },
  actions: {
    getList(store) {
      store.commit('setLoading', true);

      return api.getSvgList().then(({ data }) => {
        store.commit('setSvg', data);
      }).catch((err) => {
        message.error(`Ошибка ${err.response.status}!`);
      }).finally(() => {
        store.commit('setLoading', false);
      });
    },

    create({ dispatch }, { name, file }) {
      const fd = new FormData();
      fd.append('name', name);
      fd.append('file', file);

      return api.createSvg(fd).finally(() => dispatch('getList'));
    },

    openUploadModal({ commit }) {
      commit('setUploadModalVisibility', true);
    },
    closeUploadModal({ commit }) {
      commit('setUploadModalVisibility', false);
    },

    deleteSvgByName({ dispatch, commit }, data) {
      commit('setLoading', true);
      const directory = data.is_hidden ? 'hidden' : 'public';
      return api.deleteSvgByName(directory, data.name).finally(() => dispatch('getList'));
    },

    archiveSvgByName({ dispatch, commit }, name) {
      commit('setLoading', true);
      return api.archiveSvgByName(name).finally(() => dispatch('getList'));
    },

    restoreSvgByName({ dispatch, commit }, name) {
      commit('setLoading', true);
      return api.restoreSvgByName(name).finally(() => dispatch('getList'));
    },
  },
};
