import store from '@/store';
import { getRoleById } from '@/enums/roles';
import { publicRoutes } from '../publicRoutes';

const AuthMiddleware = (to, from, next) => {
  const { isAuth, currentUser } = store.state.auth;
  document.title = `${to.meta.name}${to.meta.name.length ? ' - ' : ''}HART Estate`;
  if (to.path === '/auth') {
    if (isAuth) {
      const currentRole = getRoleById(currentUser.roles[0].id);
      next(currentRole.startPage);
      return;
    }
    next();
    return;
  }

  if (publicRoutes.some(({ name }) => to.name === name)) {
    next();
    return;
  }

  if (!isAuth && to.path !== '/auth') {
    next(`/auth?targetPage=${to.path}`);
    return;
  }

  next();
};

export { AuthMiddleware };
