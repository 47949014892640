import { httpClient } from '@/httpClient';

export default {
  namespaced: true,
  state: {
    items: [],
    page: 1,
    total: 0,
    perPage: 15,
    isLoading: false,

    current: {
      isLoading: false,
      program: null,
      list: [],
      missingList: [],
    },

    uploadModal: {
      isVisible: false,
      isOpening: false,
    },
  },
  mutations: {
    setApartmentPrograms(state, programs) {
      state.items = programs;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setPage(state, page) {
      state.page = page;
    },
    setPagination(state, { total, perPage }) {
      state.total = total;
      state.perPage = perPage;
    },
    setUploadModalVisibility(state, visibility) {
      state.uploadModal.isVisible = visibility;
    },
    setUploadModelOpening(state, openState) {
      state.uploadModal.isOpening = openState;
    },
    setCurrent(state, { program, list, missingList }) {
      state.current.list = list;
      state.current.missingList = missingList;
      state.current.program = program;
    },
    setCurrentLoading(state, loading) {
      state.current.isLoading = loading;
    },
  },
  actions: {
    getList(store) {
      store.commit('setLoading', true);

      const params = {
        page: store.state.page,
      };

      httpClient('/api/crm/apartment_programs', { params }).then(({ data }) => {
        store.commit('setPagination', {
          total: data.total, perPage: data.perPage,
        });
        store.commit('setApartmentPrograms', data.items);
        store.commit('setLoading', false);
      });
    },
    changePage({ commit, dispatch }, page) {
      commit('setPage', page);
      dispatch('getList');
    },
    openUploadModal({ commit, dispatch }) {
      commit('setUploadModelOpening', true);
      dispatch('crm/getTagsList', null, { root: true }).then(() => {
        commit('setUploadModalVisibility', true);
        commit('setUploadModelOpening', false);
      });
    },
    closeUploadModal({ commit }) {
      commit('setUploadModalVisibility', false);
    },
    uploadExcel({ commit, state }, { file, tagId }) {
      const fd = new FormData();
      fd.append('crm_tag_id', tagId);
      fd.append('file', file);
      httpClient.post('/api/crm/apartment_programs', fd).then(({ data }) => {
        commit('setApartmentPrograms', [
          data,
          ...state.items,
        ]);
      });
    },
    async get({ commit }, id) {
      commit('setCurrentLoading', true);

      return httpClient.get(`/api/crm/apartment_programs/${id}`).then(({ data }) => {
        const {
          apartment_program: program,
          not_in: missingList,
          in: list,
        } = data;

        commit('setCurrent', { program, list, missingList });

        commit('setCurrentLoading', false);
      });
    },
  },
};
