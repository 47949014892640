import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    tagsList: [],
  },
  mutations: {
    setTagsList(state, data) {
      state.tagsList = data;
    },
  },
  actions: {
    getTagsList(store) {
      return api.getTagsList().then((resp) => {
        const { data } = resp;
        store.commit('setTagsList', data);
        return data;
      });
    },
    createTag(store, request) {
      return api.createTag(request).then((resp) => resp);
    },
    editTag(store, request) {
      return api.editTag(request).then((resp) => resp);
    },
    deleteTag(store, id) {
      return api.deleteTag(id).then((resp) => resp);
    },
  },
};
