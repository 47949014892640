const authRoutes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../Pages/Auth/index.vue'),
    meta: {
      name: 'Авторизация',
      roles: [],
    },
  },
];

export { authRoutes };
