<template>
  <a-layout-header :style="style">
    <a-page-header :title="title" />
  </a-layout-header>
</template>

<script>

import { useRoute } from 'vue-router';
import { computed } from 'vue';

const STYLE = {
  position: 'fixed',
  zIndex: 5,
  width: '100%',
  background: '#FFFFFF',
  boxShadow: '35px 0 25px rgba(0, 0, 0, 0.1)',
  padding: 0,
};

export default {
  name: 'AppHeader',

  setup() {
    const route = useRoute();

    return {
      title: computed(() => (route.meta.name ?? 'HART Estate')),
      style: STYLE,
    };
  },
};
</script>

<style lang="css">
  .ant-layout-header .ant-page-header-heading-left {
    margin: 0;
  }
</style>
