import { api } from '@/api';
import { PlanStatus } from '@/enums/planStatus';
import { PlanType } from '@/enums/planType';

export default {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    currentPage: 1,
    perPage: 50,

    isLoading: false,
    cuttingItems: [],
    currentCuttingPlanId: null,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setTotal(state, total) {
      state.total = total;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setPerPage(state, perPage) {
      state.perPage = perPage;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setCuttingItems(state, cuttingItems) {
      state.cuttingItems = cuttingItems;
    },
    setCurrentCuttingPlanId(state, id) {
      state.currentCuttingPlanId = id;
    },
  },
  actions: {
    async getPlansList({ commit, state }, params) {
      commit('startLoading');
      const response = await api.getServicePlans(PlanType.Type2d, {
        ...params,
        per_page: state.perPage ?? null,
      });

      const {
        items,
        total,
        currentPage,
        perPage,
      } = response.data;

      commit('setItems', items);
      commit('setTotal', total);
      commit('setCurrentPage', currentPage);
      commit('setPerPage', perPage);
      commit('endLoading');
    },
    async getPlansList3d({ commit, state }, params) {
      commit('startLoading');
      const response = await api.getServicePlans(PlanType.Type3d, {
        ...params,
        per_page: state.perPage ?? null,
      });

      const {
        items,
        total,
        currentPage,
        perPage,
      } = response.data;

      commit('setItems', items);
      commit('setTotal', total);
      commit('setCurrentPage', currentPage);
      commit('setPerPage', perPage);
      commit('endLoading');
    },
    async changePlanStatus3d(_, { id }) {
      return api.changePlanStatus3d({
        id,
        status: PlanStatus.ToDo,
      });
    },

    getCuttingPlansList({ commit }, params) {
      return api.getCuttingPlansList(params).then(({ data }) => {
        commit('setCuttingItems', data);
      });
    },

    getToWorkPlanCutting({ commit }) {
      return api.getToWorkPlanCutting().then(({ data }) => {
        commit('setCurrentCuttingPlanId', data.id);
      });
    },

    postponePlanCutting(_, id) {
      return api.postponeCuttingPlan(id);
    },

    unpostponePlanCutting(_, id) {
      return api.unpostponeCuttingPlan(id);
    },
  },
};
