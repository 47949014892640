import store from '@/store';
import { getRoleById } from '@/enums/roles';

const isUserHasPermission = (userRoles, routeRoles) => {
  if (!routeRoles.length) return true;
  return userRoles.some((userRole) => routeRoles.some((routeRole) => routeRole === userRole.name));
};

const PermissionMiddleware = (to, from, next) => {
  const { currentUser } = store.state.auth;
  const currentRole = getRoleById(currentUser.roles[0].id);

  if (!isUserHasPermission(currentUser.roles, to.meta.roles)) {
    next(currentRole.startPage);
    return;
  }

  next();
};

export {
  isUserHasPermission,
  PermissionMiddleware,
};
