import { httpClient } from '@/httpClient';
import { PlanType } from '@/enums/planType';
import { PlanStatus } from '@/enums/planStatus';
import { getStatusColorByNum, getStatusTextByNum } from '@/enums/PlanStatusEnum';

import { api } from '@/api';
import { message } from 'ant-design-vue';

export default {
  namespaced: true,
  state: {
    isPlansLoading: false,
    plans: [],
    total: 0,
    page: 1,
    perPage: 10,

    type: PlanType.Type2d,

    isUserRejectedPlansInfoLoading: false,
    isUserHasRejectedPlans: false,

    availableCount: -1,
  },
  mutations: {
    setType(state, type) {
      state.type = type;
    },
    setMyPlansIsLoading(state) {
      state.isPlansLoading = true;
    },
    setMyPlansIsLoaded(state) {
      state.isPlansLoading = false;
    },
    setMyPlans(state, plans) {
      state.plans = plans;
    },
    setMyPlansPage(state, page) {
      state.page = page;
    },
    setMyPlansTotal(state, total) {
      state.total = total;
    },
    setPerPage(state, perPage) {
      state.perPage = perPage;
    },
    setUserHasRejectedPlansLoading(state) {
      state.isUserRejectedPlansInfoLoading = true;
    },
    setUserHasRejectedPlansLoaded(state) {
      state.isUserRejectedPlansInfoLoading = false;
    },
    setIsUserHasRejectedPlans(state, hasRejectedPlans) {
      state.isUserHasRejectedPlans = hasRejectedPlans;
    },
    setAvailableCount(state, count) {
      state.availableCount = count;
    },
  },
  actions: {
    setType({ commit }, type) {
      if (Object.values(PlanType).includes(type)) {
        commit('setType', type);
      }
    },
    async get({ commit, state, rootState }) {
      commit('setMyPlansIsLoading');

      const response = await api.getServicePlans(state.type, {
        page: state.page,
        perPage: state.perPage,
        status: [PlanStatus.InProgress, PlanStatus.Rejected, PlanStatus.AwaitingVerification],
        userIds: [rootState.auth.currentUser.id],
      });

      const { items, total, perPage } = response.data;

      const plans = items.map((plan) => ({
        ...plan,
        key: plan.id,
        status: {
          id: plan.status,
          text: getStatusTextByNum(plan.status),
          color: getStatusColorByNum(plan.status),
        },
      }));

      commit('setMyPlansTotal', total);
      commit('setMyPlans', plans);
      commit('setPerPage', perPage);
      commit('setMyPlansIsLoaded');
    },
    setPage({ commit, dispatch }, page = 1) {
      commit('setMyPlansPage', page);
      dispatch('get');
    },
    checkIsUserHasRejectedPlans({ commit }) {
      commit('setUserHasRejectedPlansLoading');

      httpClient.get('/api/crm/plans/ninja_has_rejected').then((resp) => {
        commit('setIsUserHasRejectedPlans', resp.data.result);

        commit('setUserHasRejectedPlansLoaded');
      });
    },
    getAvailableCount({ commit, state }) {
      api.getToWorkAvailableCount({ type: state.type })
        .then(({ data }) => commit('setAvailableCount', data.availableCount));
    },
    async getPlanToWork({ state }) {
      const response = await httpClient.post('/api/crm/plans/get_oldest_to_work', { type: state.type });

      if (response.blocked) {
        message.warning('Планировка уже взята, повторите запрос');
        return null;
      }
      if (response.status === 204) {
        return null;
      }
      return response.data.id;
    },
  },
};
