import { createRouter, createWebHistory } from 'vue-router';
import { authRoutes } from '@/router/authRoutes';
import { serviceRoutes } from '@/router/serviceRoutes';
import { publicRoutes } from '@/router/publicRoutes';
import { crmRoutes } from '@/router/crmRoutes';
import { AuthMiddleware } from '@/router/Middleware/AuthMiddleware';

const routes = [
  ...authRoutes,
  ...serviceRoutes,
  ...publicRoutes,
  ...crmRoutes,

  {
    path: '/:pathMatch(.*)*',
    redirect: '/crm/plans',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(AuthMiddleware);

export { router, routes };
