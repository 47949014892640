<template>
  <a-layout-sider
    :zero-width-trigger-style="{ top:'240px' }"
    :collapsed="state.collapsed"
    theme="light"
    width="250"
    collapsible
    breakpoint="lg"
    :collapsed-width="state.collapsedWidth"
    @collapse="onCollapse"
    @breakpoint="onBreakpoint">
    <div :style="{ padding: '.75rem', height: '64px' }">
      <img
        style="width: 100%; height: 100%; max-width: 190px; display: block; object-fit: contain;"
        src="@/assets/img/logo_getfloorplan.png"
        alt="Logo">
    </div>
    <a-menu
      style="height: calc(100% - 64px); overflow: hidden auto;"
      :open-keys="state.openKeys"
      :selected-keys="[path]"
      mode="inline">
      <template
        v-for="(innerRoute) in filteredNavbarRoutes"
        :key="innerRoute.id">
        <a-menu-item
          v-if="!innerRoute.children"
          :key="innerRoute.path">
          <router-link :to="innerRoute.path">
            {{ innerRoute.title }}
          </router-link>
        </a-menu-item>

        <a-sub-menu
          v-if="innerRoute.children"
          :key="innerRoute.title">
          <template #title>
            <AreaChartOutlined v-if="innerRoute.title === 'CRM'" />
            <ControlOutlined v-if="innerRoute.title === 'SERVICE'" />
            <span>{{ innerRoute.title }}</span>
          </template>

          <a-menu-item
            v-for="child in innerRoute.children"
            :key="child.path">
            <router-link :to="child.path">
              {{ child.title }}
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>

      <a-sub-menu key="USER">
        <template #title>
          <UserOutlined />
          <span>USER</span>
        </template>
        <a-menu-item style="pointer-events: none;">
          Логин: {{ currentUser.name }}
        </a-menu-item>
        <a-menu-item
          v-for="(role, key) in currentUser.roles"
          :key="key"
          style="pointer-events: none;">
          Роль: {{ role.name }}
        </a-menu-item>
        <a-menu-item
          key="logOut"
          @click="logOut">
          <ExportOutlined />
          <span>Выход</span>
        </a-menu-item>
      </a-sub-menu>

      <div
        v-if="isWaitDurationVisible && !state.collapsed">
        <a-statistic
          :value="formatTime(timeData?.preview)"
          :style="{paddingLeft: '24px', marginBottom: '1rem', marginTop: '1rem'}">
          <template #title>
            <span>Превью</span>
            <a-tooltip placement="right">
              <template #title>
                <span>{{ `Рассчитано за последние ${config.waitDuration} минут` }}</span>
              </template>
              <question-circle-outlined style="margin-left: 5px" />
            </a-tooltip>
          </template>
        </a-statistic>

        <a-statistic
          :value="formatTime(timeData?.result)"
          :style="{paddingLeft: '24px'}">
          <template #title>
            <span>Результат</span>
            <a-tooltip placement="right">
              <template #title>
                <span>{{ `Рассчитано за последние ${config.waitDuration} минут` }}</span>
              </template>
              <question-circle-outlined style="margin-left: 5px" />
            </a-tooltip>
          </template>
        </a-statistic>
      </div>

      <a-menu-item style="margin-top: 2rem">
        <p
          v-if="!state.collapsed"
          style="color: #bfbfbf">
          {{ appVersion ?? '' }}
        </p>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script setup>
import {
  reactive, computed, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { routes } from '@/router';
import { isUserHasPermission } from '@/router/Middleware/PermissionMiddleware';
import {
  AreaChartOutlined,
  ControlOutlined,
  UserOutlined,
  ExportOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons-vue';
import getFlag from '@/helpers/getFlag';
import { httpClient } from '@/httpClient';
import config from '@/config'; // eslint-disable-line import/extensions
import moment from 'moment';
import { pluralize } from '@/helpers/pluralizeHelper';

const COLLAPSED_WIDTH = 80;
const COLLAPSED_WIDTH_RESPONSIVE = 0;

const NAVBAR_ROUTES = [
  {
    title: 'CRM',
    children: [
      { title: 'Мои планировки', path: '/crm/my-plans' },
      { title: 'Нарезка планировок', path: '/crm/plans/cutting' },
      { title: 'Планировки', path: '/crm/plans' },
      { title: 'Загрузка планировок', path: '/crm/upload' },
      { title: 'Квартирограммы', path: '/crm/apartment-programs' },
      { title: 'Апартаменты', path: '/crm/apartments' },
      { title: 'Ниндзя-статистика', path: '/crm/statistic/ninja' },
      { title: 'Статистика по отдельным ниндзя', path: '/crm/statistic/particularNinja' },
      { title: 'База планировок', path: '/crm/plans-database' },
      { title: 'Заказчики', path: '/crm/customers' },
    ],
  },
  {
    title: 'SERVICE',
    children: [
      { title: 'Обработать', path: '/service' },
      { title: 'Планировки', path: '/plans' },
      { title: 'Пользователи', path: '/users' },
      { title: 'Мебель', path: '/furniture' },
      { title: 'Конструктор SVG - Фурнитура', path: '/svg-editor/icons' },
      { title: 'Дизайн-документы', path: '/design-files' },
      { title: 'Сущности', path: '/substances' },
      { title: 'Обучение', path: '/neural_dataset_selection' },
    ],
  },
];

const router = useRouter();
const route = useRoute();
const store = useStore();

const path = computed(() => route.path);

const isLocalCollapsed = Boolean(localStorage.getItem('collapsed'));

const isCustomersVisible = getFlag('isCustomersVisible');
const isDatasetSelectionPlansVisible = getFlag('isDatasetSelectionPlansVisible');
const isWaitDurationVisible = getFlag('isWaitDurationVisible');

const timeData = ref(null);

const state = reactive({
  openKeys: isLocalCollapsed ? [] : ['CRM', 'SERVICE', 'PLANS 3D', 'USER'],
  collapsed: isLocalCollapsed,
  collapsedWidth: COLLAPSED_WIDTH,
});

const onCollapse = (isCollapsed, type) => {
  if (type !== 'clickTrigger') return;

  state.collapsed = isCollapsed;

  if (isCollapsed) {
    localStorage.setItem('collapsed', true);
    return;
  }

  localStorage.removeItem('collapsed');
};

const onBreakpoint = (isLessThanBreakpoint) => (isLessThanBreakpoint
  ? state.collapsedWidth = COLLAPSED_WIDTH_RESPONSIVE
  : state.collapsedWidth = COLLAPSED_WIDTH);

const currentUser = computed(() => store.state.auth.currentUser);

const filteredNavbarRoutes = computed(() => NAVBAR_ROUTES.map((routeGroup) => ({
  ...routeGroup,
  children: routeGroup.children.filter((navbarRoute) => {
    const currentRoute = routes.find((globalRoute) => globalRoute.path === navbarRoute.path);
    return (
      currentRoute
        && isUserHasPermission(currentUser.value.roles, currentRoute.meta.roles)
        && !(navbarRoute.title === 'Заказчики' && !isCustomersVisible)
    )
        && !(navbarRoute.title === 'Обучение' && !isDatasetSelectionPlansVisible);
  }),
})).filter(({ children }) => children.length > 0));

const logOut = () => {
  store.commit('auth/logout');
  router.push('/auth');
};

const appVersion = process.env.VUE_APP_BACKOFFICE_VERSION;

const displayedEnv = config.appEnv === 'dev' ? 'stage' : config.appEnv;

const fetchData = async () => {
  try {
    const response = await httpClient.get('/api/v1/datadog/metrics/wait_duration/', {
      baseURL: config.apiControlPanel,
      params: {
        from_time: config.waitDuration,
        environment: displayedEnv,
        unreal: 'prevres',
      },
    });
    timeData.value = response.data.data;
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
    timeData.value = null;
  }
};

const formatTime = (previewData) => {
  if (!previewData || previewData.wait_duration === undefined) return 'Сервис недоступен';
  if (previewData.wait_duration === null) return '0';

  const waitDuration = previewData.wait_duration;
  const duration = moment.duration(waitDuration, 'minutes');

  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const formattedMinutes = minutes > 0 ? `${minutes} ${pluralize(minutes, 'минута', 'минуты', 'минут')}` : '';
  const formattedSeconds = seconds > 0 ? `${seconds} ${pluralize(seconds, 'секунда', 'секунды', 'секунд')}` : '';

  return `${formattedMinutes} ${formattedSeconds}`.trim();
};

if (isWaitDurationVisible) {
  fetchData();
}

</script>

<style lang="css">
  .ant-layout .ant-layout-sider {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    height: 100vh;
  }
  @media (max-width: 992px) {
    .ant-layout .ant-layout-sider {
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.25));
      box-shadow: 40px 0 30px rgba(0, 0, 0, 0.1);
      position: fixed;
      z-index: 6;
    }
  }

  .ant-statistic-content {
    font-size: 14px !important;
  }
</style>
