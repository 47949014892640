import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    stylesList: [],
    typesList: [],
    presetsList: [],
    styleMaterials: [],
    unrealEnvironment: [],
    brandingsList: [],
    defaultPreset: null,
    defaultUnrealEnvironment: 'Default',
  },
  mutations: {
    setStylesList(state, styles) {
      state.stylesList = styles;
    },
    setTypesList(state, types) {
      state.typesList = types;
    },
    setPresetsList(state, presets) {
      state.presetsList = presets.data;
      state.defaultPreset = presets.default;
    },
    setStyleMaterialsList(state, materials) {
      state.styleMaterials = materials;
    },
    setUnrealEnvironment(state, unrealEnvironment) {
      state.unrealEnvironment = unrealEnvironment;
    },
    setBrandingsList(state, brandingsList) {
      state.brandingsList = brandingsList;
    },
  },

  actions: {
    getStylesList(store) {
      return api.getStylesListForFurniture().then((resp) => {
        const { data } = resp;
        store.commit('setStylesList', data);
        return data;
      });
    },
    createStyle(store, request) {
      return api.createStyleForFurniture(request).then((resp) => resp);
    },
    editStyle(store, request) {
      return api.editStyleForFurniture(request).then((resp) => resp);
    },
    deleteStyle(store, id) {
      return api.deleteStyleForFurniture(id).then((resp) => resp);
    },

    getTypesList(store) {
      return api.getRoomTypesList().then((resp) => {
        const { data } = resp;
        store.commit('setTypesList', data);
        return data;
      });
    },
    createType(store, request) {
      return api.createRoomType(request).then((resp) => resp);
    },
    editType(store, request) {
      return api.editRoomType(request).then((resp) => resp);
    },
    deleteType(store, id) {
      return api.deleteRoomType(id).then((resp) => resp);
    },

    createPreset(store, request) {
      return api.createPreset(request).then((resp) => resp);
    },
    getPresetsList(store) {
      return api.getPresets().then((resp) => {
        const { data } = resp;
        store.commit('setPresetsList', data);
        return data;
      });
    },
    editPreset(store, request) {
      return api.editPreset(request).then((resp) => resp);
    },
    deletePreset(store, id) {
      return api.deletePreset(id).then((resp) => resp);
    },

    getStyleMaterialsList(store) {
      return api.getStyleMaterials().then((resp) => {
        const { data } = resp;
        store.commit('setStyleMaterialsList', data);
        return data;
      });
    },
    createStyleMaterial({ state, commit }, request) {
      return api.createStyleMaterial(request).then(({ data }) => {
        commit('setStyleMaterialsList', [
          ...state.styleMaterials,
          data,
        ]);
        return data;
      });
    },
    deleteStyleMaterial({ state, commit }, id) {
      return api.deleteStyleMaterial(id).then(() => {
        commit('setStyleMaterialsList', state.styleMaterials.filter((item) => item.id !== id));
      });
    },

    getUnrealEnvironment(store) {
      return api.getUnrealEnvironment().then((resp) => {
        const { data } = resp;
        store.commit('setUnrealEnvironment', data);
        return data;
      });
    },

    createUnrealEnvironment(store, request) {
      return api.createUnrealEnvironment(request).then((resp) => resp);
    },

    editUnrealEnvironment(store, request) {
      return api.editUnrealEnvironment(request).then((resp) => resp);
    },

    deleteUnrealEnvironment(store, id) {
      return api.deleteUnrealEnvironment(id).then((resp) => resp);
    },

    getBrandingsList(store) {
      return api.getBrandingsList().then((resp) => {
        const { data } = resp;
        store.commit('setBrandingsList', data);
        return data;
      });
    },

    createBranding(store, request) {
      return api.createBranding(request).then((resp) => resp);
    },

    editBranding(store, request) {
      return api.editBranding(request).then((resp) => resp);
    },

    deleteBranding(store, id) {
      return api.deleteBranding(id).then((resp) => resp);
    },
  },
};
