import { api } from '@/api';

export default {
  namespaced: true,
  state: {
    customersList: [],
  },
  mutations: {
    setCustomersList(state, customersList) {
      state.customersList = customersList;
    },
  },

  actions: {
    getCustomersList(store) {
      return api.getCustomersList().then((resp) => {
        const { data } = resp;
        store.commit('setCustomersList', data);
        return data;
      });
    },

    createCustomer(store, request) {
      return api.createCustomer(request).then((resp) => resp);
    },

    editCustomer(store, request) {
      return api.editCustomer(request).then((resp) => resp);
    },

    deleteCustomer(store, id) {
      return api.deleteCustomer(id).then((resp) => resp);
    },
  },
};
