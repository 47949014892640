import { fileDownloadConfig, httpClient } from '@/httpClient';
import { api } from '@/api';
import config from '@/config'; // eslint-disable-line import/extensions
import { getFilesAsNameAndUrl } from '../../../utils/getFilesAsNameAndUrl';
import { downloadFile } from '../../../utils/downloadFile';

export default {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    perPage: 15,
    isLoading: false,

    current: {
      isLoading: false,
    },

    uploadModal: {
      isVisible: false,
      isOpening: false,
    },

    isArchiveCreating: false,
    isSourcesArchiveCreating: false,
    isOriginalPlansArchiveCreating: false,

    plans: {
      isLoading: false,
      items: [],
    },
  },
  mutations: {
    setApartments(state, programs) {
      state.items = programs;
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
    setPagination(state, { total, perPage }) {
      state.total = total;
      state.perPage = perPage;
    },
    setUploadModalVisibility(state, visibility) {
      state.uploadModal.isVisible = visibility;
    },
    setUploadModelOpening(state, openState) {
      state.uploadModal.isOpening = openState;
    },
    setCurrent(state, data) {
      const identifiers = data.additional.id
        ? data.additional.id.map((id) => id.trim())
        : [];

      state.current = {
        isLoading: state.current.isLoading,
        ...data,
        status: Number(data.status),
        identifiers,
      };
    },
    setPlansLoading(state, value) {
      state.plans.isLoading = value;
    },
    setPlansItems(state, items) {
      state.plans.items = items;
    },
    setCurrentLoading(state, loading) {
      state.current.isLoading = loading;
    },
    setCurrentId(state, id) {
      state.current.id = id;
    },
    setCurrentName(state, name) {
      state.current.name = name;
    },
    setIsArchiveCreating(state, isCreating) {
      state.isArchiveCreating = isCreating;
    },
    setIsSourcesArchiveCreating(state, isCreating) {
      state.isSourcesArchiveCreating = isCreating;
    },
    setIsOriginalPlansArchiveCreating(state, isCreating) {
      state.isOriginalPlansArchiveCreating = isCreating;
    },
  },
  actions: {
    getList(store, filter = {}) {
      store.commit('setLoading', true);

      const params = {
        page: filter.page,
      };

      if (Object.keys(filter).length !== 0) {
        const normalizedFilter = {
          name: filter.name ?? null,
          crm_tag_id: filter.crmTagId ?? null,
          after_date: filter.dates[0]?.toISOString() ?? null,
          before_date: filter.dates[1]?.toISOString() ?? null,
          priority: filter.priorityId ?? null,
          status: filter.statusId ?? null,
          responsible_ninja: filter.responsibleNinja ?? null,
          responsible_ninja_3d: filter.responsibleNinja3d ?? null,
          address: filter.address ?? null,
          complex: filter.complex ?? null,
          developer: filter.developer ?? null,
          region: filter.region ?? null,
        };

        const entries = Object.entries(normalizedFilter);

        const filledFilterEntries = entries.filter(([, value]) => !!value);

        if (filledFilterEntries.length) {
          params.filter = Object.fromEntries(filledFilterEntries);
        }
      }

      return api.getApartmentList(params, filter)
        .then(({ data }) => {
          store.commit('setPagination', data);
          store.commit('setApartments', data.items);
        }).finally(() => store.commit('setLoading', false));
    },
    getPlans({ commit }, { id, type }) {
      commit('setPlansLoading', true);

      return api.getApartmentsPlansList(id, type).then(({ data }) => {
        commit('setPlansItems', data.plans);
        commit('setCurrentId', data.id);
        commit('setCurrentName', data.name);
        return data.plans;
      }).finally(() => commit('setPlansLoading', false));
    },
    get({ commit }, id) {
      commit('setCurrentLoading', true);

      return httpClient.get(`/api/crm/apartments/${id}`).then(({ data }) => {
        commit('setCurrent', data);
        commit('setCurrentLoading', false);
      });
    },
    openUploadModal({ commit }) {
      commit('setUploadModalVisibility', true);
    },
    closeUploadModal({ commit }) {
      commit('setUploadModalVisibility', false);
    },
    create({ commit, state }, {
      file, name, ids, pictures, description, sameNinjaFor2dAnd3dPlans,
    }) {
      const fd = new FormData();
      fd.append('name', name);
      if (ids) {
        ids.split(',').forEach((id) => fd.append('additional[id][]', id.trim()));
      }
      if (description) {
        fd.append('description', description);
      }
      if (sameNinjaFor2dAnd3dPlans !== undefined && sameNinjaFor2dAnd3dPlans !== null) {
        fd.append('same_ninja_for_2d_and_3d_plans', sameNinjaFor2dAnd3dPlans ? '1' : '0');
      }
      if (file) {
        fd.append('apartment_program', file);
      }
      pictures.forEach((picture, index) => fd.append(`floor_data[${index}]`, picture));

      return httpClient.post('/api/crm/apartments', fd).then(({ data }) => {
        commit('setApartments', [
          data,
          ...state.items,
        ]);
      });
    },
    update({ commit, state }, {
      name, description, additional, region, developer, complex, address, sameNinjaFor2dAnd3dPlans,
    }) {
      const { id } = state.current;
      const sameNinjaFor2dAnd3dPlansNumber = sameNinjaFor2dAnd3dPlans ? 1 : 0;
      return api.updateApartment(id, {
        name,
        description,
        additional,
        region,
        developer,
        complex,
        address,
        sameNinjaFor2dAnd3dPlans: sameNinjaFor2dAnd3dPlansNumber,
      }).then(({ data }) => {
        commit('setCurrent', data);
      });
    },
    sendInProgress({ dispatch, state }) {
      const { id } = state.current;
      return httpClient.post(`/api/crm/apartments/${id}/send_in_progress`).then(() => {
        dispatch('get', id);
      });
    },
    setResponsibleNinja2d({ commit, state }, ninjaIds) {
      const { id } = state.current;
      return api.setApartmentResponsibleNinja(id, { ninjaIds }).then(({ data }) => commit('setCurrent', data));
    },
    setResponsibleNinja3d({ commit, state }, ninjaIds) {
      const { id } = state.current;
      return api.setApartmentResponsibleNinja3d(id, { ninjaIds }).then(({ data }) => commit('setCurrent', data));
    },
    uploadFile({ commit, state }, file) {
      const { id } = state.current;
      const fd = new FormData();
      fd.append('file', file);
      return httpClient.post(`/api/crm/apartments/${id}/file`, fd).then(({ data }) => {
        commit('setCurrent', data);
      });
    },
    uploadExcel({ commit, state }, file) {
      const { id } = state.current;
      const fd = new FormData();
      fd.append('apartment_program', file);
      return httpClient.post(`/api/crm/apartments/${id}/change_program`, fd).then(({ data }) => {
        commit('setCurrent', data);
      });
    },
    deleteImage({ dispatch, state }, path) {
      const { id } = state.current;
      const fileName = path.replace(`${config.apiUrl}/crm_apartments_storage/${id}/files/`, '');
      return httpClient.delete(`/api/crm/apartments/${id}/file?file=${fileName}`).then(() => {
        dispatch('get', id);
      });
    },
    downloadPlansArchive({ commit }, id) {
      commit('setIsArchiveCreating', true);

      httpClient.get(`/api/crm/apartments/${id}/download`, fileDownloadConfig)
        .then(({ data }) => downloadFile(data, `apartment_${id}.zip`))
        .finally(() => commit('setIsArchiveCreating', false));
    },
    downloadSourcesArchive({ commit }, id) {
      commit('setIsSourcesArchiveCreating', true);

      httpClient.get(`/api/crm/apartments/${id}/download_source_files`, fileDownloadConfig)
        .then(({ data }) => downloadFile(data, `apartment_sources_${id}.zip`))
        .finally(() => commit('setIsSourcesArchiveCreating', false));
    },
    downloadOriginalPlansArchive({ commit }, id) {
      commit('setIsOriginalPlansArchiveCreating', true);

      return api.getOriginalPlansArchive(id)
        .then(({ data }) => downloadFile(data, `apartment_original_plans_${id}.zip`))
        .finally(() => commit('setIsOriginalPlansArchiveCreating', false));
    },
    async changePriority({ commit, state }, priority) {
      const { data } = await api.changeApartmentPriority(state.current.id, priority);

      commit('setCurrent', data);

      return data;
    },
  },
  getters: {
    filesList: (state) => {
      if (!state.current || !state.current.files) {
        return [];
      }

      return getFilesAsNameAndUrl(state.current.files);
    },
  },
};
