<template>
  <router-view v-if="isRouteSpecial" />

  <a-layout
    v-else
    :style="{ height: '100vh' }">
    <nav-bar v-if="isAuth" />

    <a-layout :style="{ overflow: 'auto', height: '100vh' }">
      <app-header v-if="!isResponsive" />

      <div :style="style">
        <router-view />
        <yandex-metrika />
      </div>
    </a-layout>
  </a-layout>
</template>

<script>
import {
  computed, defineComponent, onBeforeUnmount, onMounted,
} from 'vue';
import { useStore } from 'vuex';

import NavBar from '@/Components/NavBar.vue';
import AppHeader from '@/Components/AppHeader.vue';

import { breakpoints } from '@/consts/breakpoints';
import { publicRoutes } from '@/router/publicRoutes';
import YandexMetrika from './YandexMetrika.vue';

export default defineComponent({
  components: { NavBar, AppHeader, YandexMetrika },
  setup() {
    const store = useStore();

    const isResponsive = computed(() => store.state.isResponsive);

    const isRouteSpecial = publicRoutes.some(({ pathname }) => location.pathname.includes(pathname));

    store.commit('setResponsive', window.innerWidth < breakpoints.lg);

    const onResize = () => {
      store.commit('setResponsive', window.innerWidth < breakpoints.lg);
    };

    onMounted(() => {
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount(() => window.removeEventListener('resize', onResize));

    const style = computed(() => ({
      margin: isResponsive.value ? '0' : '64px 0 10px 0',
      minHeight: isResponsive.value ? 'calc(100vh - 10px)' : 'calc(100vh - 74px)',
    }));

    return {
      isAuth: computed(() => store.state.auth.isAuth),
      isRouteSpecial,
      isResponsive,
      style,
    };
  },
});
</script>

<style>
body {
  background-color: rgba(243, 244, 246, 1) !important;
}

svg {
  vertical-align: baseline;
}

img {
  display: inline-block;
}

*, *:before, *:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-content {
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}

.ant-table-wrapper .ant-pagination.ant-table-pagination {
  padding: 0 16px
}

.bordered-footer-table .ant-table-footer {
  border-bottom: 1px solid #f0f0f0;
}

#app .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}
</style>
